@media (max-width: 1720px) {
    .mint-goat {
        top: 85px;
        height: calc(100% - 85px);
    }

    .header-flex nav>ul>li.separator-menu {
        margin-left: 0px;
        margin-right: 0px;
    }

    .flex-mint-fieno {
        margin-left: 370px;
    }
}

@media(max-width: 1400px) {
    .header-flex nav ul li a {
        padding: 10px 15px;
    }

    .flex-mint-fieno {
        margin-left: 0px;
    }

    .container-account .account-goatech-g212 {
        opacity: 0.5;
    }
}

@media (max-width: 1200px) {

    p {
        font-size: 16px;
        line-height: 22.4px;
    }

    h3 {
        font-size: 24px;
        line-height: 29.3px;
    }

    .container-top {
        top: 70px;
        position: relative;
    }

    .h3-section2 {
        font-size: 26px;
        line-height: 34px;
    }

    .parag-section1 {
        font-size: 16px;
        line-height: 20.24px;
        margin-bottom: 40px;
    }

    .parag-section2 {
        font-size: 16px !important;
        line-height: 22.4px !important;
    }

    .mobile_center {
        text-align: center;
    }

    .list-section2 {
        text-align: left;
    }

    .list-section2 li {
        font-size: 16px;
        line-height: 19.2px;
    }

    .list-section2 li svg {
        margin-right: 0px;
        width: 20px;
    }

    .faq-grid h4 {
        font-size: 20px;
        line-height: 30px;
    }

    .faq-grid .faq-grid-element p {
        margin-top: 0px;
    }

    img {
        max-width: 100%;
    }


    header,
    header .header-flex {
        height: 70px;
    }

    .menu-mobile {
        display: inline-block;
    }

    .header-flex nav {
        display: none;
    }

    .header-flex nav.mostra-menu {
        display: block;
    }

    .header-flex nav ul {
        display: block;
    }

    .header-flex nav>ul>li.separator-menu {
        display: none;
    }

    .header-flex nav {
        width: 80%;
        position: fixed;
        top: 0;
        z-index: 9999;
        height: 100%;
        right: 0px;
        max-width: 300px;
        background: rgba(21, 19, 19, 1);
        overflow-y: scroll;
    }

    .header-flex nav>ul {
        height: 100%;
    }

    .header-flex nav>ul>li {
        height: auto !important;
    }

    .header-flex nav>ul>li svg,
    .header-flex nav>ul li.social a img {
        margin-right: 10px;
    }


    .open-mobile-child:not(.mobile-child-opened) {
        display: none;
    }

    .open-mobile-child.mobile-child-opened {
        display: block;
    }

    .mobile-child-closed {
        display: none !important;
    }

    .opener-mobile-menu a {
        border-bottom: 1px solid #616161;
    }

    .open-mobile-child-arrow {
        display: block;
    }

    .open-mobile-child-arrow svg {
        margin-right: 0px !important;
    }

    .open-mobile-child-arrow {
        font-size: 22px;
        float: right;
    }

    .opener-mobile-menu>a,
    .opener-mobile-menu>a span {
        font-weight: 600 !important;
    }

    .mobile-child:not(.mobile-child-opened) {
        display: none !important;
    }

    .mobile-close-li {
        display: block;
    }

    .header-flex nav>ul>li {
        margin: 0px;
        height: 60px;
        line-height: 60px;
    }

    .dropdown {
        display: initial !important;
        width: 100%;
        top: 0px;
        position: relative;
        border-radius: 0px;
        background-color: transparent;
        box-shadow: none;
    }

    .dropdown li {
        line-height: inherit;
    }

    .dropdown li:not(:last-child) a {
        border-bottom: 0px;
    }

    .header-flex nav ul li a {
        display: block;
        padding: 0px 20px !important;
        font-size: 16px;
    }

    .header-flex nav ul li a:not(.opener-mobile-menu),
    .header-flex nav ul li a:not(.opener-mobile-menu) span {
        font-weight: 500;
    }

    .header-flex nav ul li a.connectWallet {
        border: 0px !important;
        box-shadow: none !important;
        border-bottom: 1px solid #616161 !important;
        border-radius: 0px;
    }

    .homepage-intest-photo {
        height: auto;
        margin-bottom: 20px;
        background: none !important;
    }

    .homepage-intest-photo div {
        background-size: contain !important;
    }

    .intest-goat-omar {
        background-position: top !important;
    }

    h1.intest-h1 {
        font-size: 32px;
        line-height: 39.5px;
    }

    .section1-background .section1-left,
    .section1-background .section1-right {
        max-width: 100%;
        float: unset;
    }

    .section1-background .section1-right {
        padding-top: 20px;
        text-align: center;
    }

    .section1-background .section1-left {
        margin-top: 80px;
    }

    .section1-background .section1-left img {
        position: relative;
        object-fit: contain;
        margin: 0px auto;
        display: block;

        max-width: 550px;
        width: 100%;
        left: 0px;
    }

    .section2-background .section2-left {
        max-width: 100%;
        float: unset;
        padding-top: 0px;
    }

    .section2-background .section2-right {
        float: unset;
        text-align: left;
    }

    .section2-background .section2-right {
        max-width: 100%;
    }

    .goatmap-background,
    .section2-background,
    .section-goatrex-background {
        height: auto;
    }

    .section1-background {
        height: auto;
        flex-direction: column;
        background-position: 10% 0px !important;
    }

    .goat-nation .box-discordbtn,
    .goat-fromday0 .box-discordbtn {
        text-align: center;
    }

    .goat-fromday0-decoration {
        background-position: 0px bottom !important;
        background-size: contain !important;
    }

    .faq-grid {
        flex-direction: column;
        gap: 35px;
    }

    .container-padding {
        padding-top: 80px;
        padding-bottom: 20px;
    }

    #utilities:not(.section-mint-disabled),
    #goat-nation {
        padding-top: 80px !important;
    }

    .section-mint-disabled {
        padding-top: 10px !important;
    }

    #goat-nation .section2-right {
        padding-top: 50px;
    }

    .section2-background img {
        position: relative !important;
        width: 80%;
        margin: auto;
        display: block;
        max-width: 380px !important;
    }
    .section-goatchart-background img {
        top: 0px
    }
    .section-goatchart-background .section2-right {
        padding-top: 0px !important;
    }
    .section-goatchart-background {
        padding-top: 30px;
    }
    .section-esim-background .section2-left  {
        padding-top: 30px !important;
    }
    .section-esim-background img {
        object-position: 0px;
    }

    .roadmap-cart {
        top: unset;
    }

    .roadmap-cart .point-roadmap {
        font-size: 12px;
    }

    .roadmap-grid {
        flex-direction: column;
        row-gap: 20px;
    }

    .roadmap-grid .roadmap-grid-element {
        margin-top: 0px !important;
    }

    .riga_roadmap {
        background-position: 15px 0 !important;
        background-size: cover !important;
        width: 20px;
        height: 99%;
        left: 0px;
    }

    .box-roadmap-element h4 {
        font-size: 20px;
        line-height: 40px;
    }

    .box-roadmap-element ul {
        font-size: 16px;
        line-height: 22.4px;
    }

    .roadmap-grid-element-hiddenmob {
        display: none;
    }

    .goat-section-mobile {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .goat-section-mobileai {
        padding-top: 100px !important;
        padding-bottom: 50px !important;
    }

    .art-grid {
        column-gap: 4%;
        width: 95%;
        margin: 0px auto;
        row-gap: 0px;

        margin-bottom: 40px;
    }

    .art-grid .art-grid-element {
        flex: 48%;
        margin: 0px;
    }

    .art-grid .art-grid-element {
        margin-top: 20px !important;
    }

    .art-grid-hd-mobile {
        display: none !important;
    }

    .team-grid {
        margin-top: 70px;
    }

    .parag-section-opacity {
        font-size: 16px;
        line-height: 22.4px;
    }

    .logo-footer {
        margin-top: 35px;
    }

    .roadmap {
        height: 120px !important;
        position: relative;
        top: 0px !important;
        z-index: 99999;
        background-size: contain !important;
    }

    .hp-intest-photomobile,
    .intest-goatmap_mobile {
        display: block;
    }

    .hp-intest-photomobile,
    .intest-goatmap_mobile {
        text-align: center;
    }

    .hp-intest-photodesktop,
    .intest-goatmap_desktop {
        display: none;
    }

    .intest-goatmap_mobile {
        position: relative !important;
    }

    .intest-goatmap {
        height: auto;
    }

    .goatmap-background {
        background: black !important;
    }

    .goat-alphagoat-background-mobile {
        background-position: right -60px !important;
    }

    .goat-alphagoat-background {
        margin: 0px;
    }

    .goat-alphagoat-mobile {
        margin-bottom: -80px;
    }

    .goat-alphagoat-mobile img {
        top: -30px;
    }

    .goat-alphagoat-mobile,
    .goat-academy-mobile,
    .goat-goatrex-mobile {
        display: block;
    }

    .goat-goatrex-mobile,
    .goat-ai-img-section,
    .goat-academy-mobile,
    .goatrex-mobile {
        margin-bottom: -5px;
    }

    .goat-academy-mobile img {
        width: 80%;
        margin: 0px auto;
        display: block;
    }

    .goat-goatrex-background-mobile {
        background: #0b1214 !important;
    }

    .goat-academy-background {
        background-position: right !important;
    }

    .goatrex-mobile img {
        right: 0px !important;
    }

    .lang-li {
        width: 100%;
        margin-right: 0px;
    }

    .lang-li,
    .lang-li>a,
    .dropdown_lang {
        position: initial;
    }

    .mostra-dropdown {
        display: block;
        width: 100%;
    }

    .homevideo-frame .vector-play {
        top: calc(50% - 70px);
    }

    .mint-goat {
        opacity: 0.3;
        top: 69px;
        margin-left: 0px;
        left: 0px;
        height: calc(100% - 69px);
        z-index: 2;
    }

    .mint-goat-background {
        z-index: -1;
    }

    .mint-goat-background .mint-goat-bandiere,
    .mint-goat-background .mint-goat-shadow {
        display: none;
    }

    .mint-goat-background {
        background-image: none !important;
    }

    .modal-overlay .modal {
        padding: 10px;
    }

    .mint .mint-page-columns {
        display: block;
    }

    .mint .mint-page-column:nth-child(2) {
        z-index: 2;
        position: relative;
        top: 90px;
    }

    .mint .mint-page-columns h1 {
        font-size: 44px;
        line-height: 45px;
        margin-top: 0px;
    }

    .box-mint-now .boxinput-mint-qnt,
    .mint-btn,
    .pAvailable {
        font-size: 15px;
    }

    .modalError {
        max-width: 100%;
        right: 0px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .only-mobile {
        display: initial;
    }

    .account-grid {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .account-col1 {
        display: none;
    }

    .account-grid .account-grid-col {
        margin-bottom: 20px;
        font-size: 27px;
    }

    .account-columns .account-col2 {
        width: 100%;
        max-width: 100%;
    }

    .container-top,
    .container-account,
    .account-columns,
    .account-columns .account-col2 {
        height: auto;
    }

    .account-columns {
        display: block;
        height: 100% !important;
    }

    .account-columns .account-col2 .account-padding {
        padding: 40px 30px;
    }

    .sliderWalletNft .thumb-nft {
        max-width: 100%;
    }

    .badge-claim-intest {
        margin-bottom: 40px;
        text-align: center;
    }

    .account-grid-nftwallet {
        margin-bottom: 0px;
    }

    .sliderWalletNft .thumb-nft img {
        width: 100%;
    }

    .container-account .account-goatech-g212 {
        background-position: bottom center !important;
        opacity: 0.3;
    }

    .goatech-stats h3 {
        font-size: 28px;
        margin-bottom: 20px;
        text-align: center;
    }

    .goatech-stats-cols {
        display: block;
        margin-bottom: 20px;
    }

    .goatech-stats-tabs .goatech-stats-tab .stats-tab-box {
        height: auto;
    }

    .goatech-stats-farmhousebarn,
    .goatech-stats-gt212 {
        display: none;
    }

    .goatech-stats-table td,
    .goatech-stats-table th {
        width: 100%;
        text-align: left;
        display: block;
    }

    .flex-mint-fieno {
        margin-left: 0px;
    }

    .box-mint-fieno-col1 {
        flex: 50px 1;
    }

    .only-desktop {
        display: none !important;
    }

    .container-mint-fieno {
        margin: 0px auto;
    }

    .container-mint-fieno h3 {
        margin-bottom: 25px;
        margin-top: 20px;
        text-align: center;
        font-size: 32px;
    }

    .container-mint-fieno .box-mint-title {
        text-align: center;
    }

    .account-goatech-g212 {
        background-image: none !important;
    }

    .goatech-mobile-btns {
        display: flex;
    }

    .goatech-stats-tabs .goatech-stats-tab .stats-tab-box {
        padding: 10px;
    }

    .stats-tab-valore {
        font-size: 15px;
    }

    .container-switch-network .text-switch-network {
        margin-top: 20px;
    }

    .goatech-stats-cols .goatech-stats-col60,
    .goatech-stats-cols .goatech-stats-col40 {
        width: 100%;
        margin-right: 0;
    }
    .homesection-goatmind .section2-right h3 {
        display: none;
        padding-top: 50px;
    }
    .h3-goatmind-mobile {
        display: block;
    }
    .carousel-goatmind h5 {
        font-size: 21px;
    }
    .carousel-goatmind .carousel-goatmind-descr {
        font-size: 17px;
        line-height: 24px;
    }
    .carousel-goatmind-img img {
        max-width: 400px;
        width: 100%;
    }
    .homesection-goatmind-partners h3 {
        font-size: 20px;
    }
    .homesection-goatmind .section2-left,
    .homesection-goatmind .section2-right {
        width: 100%;
    }
    .homesection-goatmind {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    .homesection-goatmind-partners .slick-dots {
        bottom: -30px;
    }
    /* Mint is live
    .first-sectionhome {
        margin-top: 50px;
    }
    */
    .first-sectionhome {
        margin-top: 0px;
    }

    .goatech-stats-table thead {
        display: none;
    }
    .goatech-stats-table tr {
        display: block;
        margin-bottom: 4px;
        border-bottom: 2px solid #9c9c9c;
    }
    .goatech-stats-table td {
        text-align: right;
        min-height: 41px;
    }
    .goatech-stats-table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }
    .goatech-stats-grafico {
        margin-bottom: 50px;
    }
    .form-insert-affiliate .affiliate-address-saved span {
        word-break: break-all;
        display: block;
        margin-top: 10px;
    }
    .levels-table .level-table-row .level-column-checks {
        font-size: 16px;
    }
    .grid-two-column, .grid-three-column {
        display: block;
    }
    .h4-section {
        font-size: 20px;
        line-height: 30px;
    }
    .flag-lang-header {
        margin-right: 10px;
    }
    .academy-grid-contents .academy-box-content {
        width: calc(50% - 20px);
        margin: 10px;
        float: left;
    }
}

@media(max-width: 1200px) {
    .container {
        padding-left: 70px;
        padding-right: 70px;
    }
}

@media (max-width: 375px) {
    .mintbtn-home {
        display: block !important;
        width: fit-content;
        margin: 0px auto;
        margin-bottom: 20px;
    }
}

@media(max-width: 450px) {
    .form-insert-affiliate input[type="text"] {
        max-width: 100%;
    }
    .form-insert-affiliate label {
        text-align: center;
    }
    .form-insert-affiliate button[type="submit"] {
        margin: 0px auto;
        display: block;
        margin-top: 10px;
    }
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
    .community-container .community-header {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .community-container .community-header img {
        height: 55px;
    }
    .goatech-license-box {
        height: 190px;
    }
    .level-table-row {
        height: 70px !important;
    }
    .level-table-row p {
        line-height: 19px;
        font-size: 16px;
    }
    .levels-table .level-table-row .level-column-checks {
        font-size: 20px;
        line-height: 18px;
    }
    .levels-table .level-table-row .level-column-checks span {
        font-size: 11px;
        display: block;
    }
    .levels-table .level-table-row .level-column-checks img {
        width: 80%;
    }
    .levels-column-labels .level-table-row {
        padding-right: 20px;
    }
    .pPriceHomeHead {
        font-size: 18px;
    }
    .nftlevels-box .nftlevels-thumb h4 {
        font-size: 29px;
    }
    .footer-disclaimer {
        font-size: 10px;
        line-height: initial;
    }
    .container-fluid .h4-section {
        margin-left: 20px;
        margin-right: 20px;
    }
    .goatech-previewimg {
        margin-bottom: 70px;
        margin-top: 10px;
    }
    .levels-table .level-table-row:first-of-type .level-column-checks {
        display: block;
        padding-top: 20px;
    }
    .container-account .column-card-licenses {
        display: block !important;
    }
    .container-account h4 {
        text-align: center;
    }
    .goatech-licenseinfo {
        display: block;
    }
    .goatech-current-lvl {
        text-align: center;
        margin-top: 15px;
    }
    .goatech-licenseinfo .box-goatech-single-license {
        float: unset;
    }
}

@media(min-width: 450px) and (min-width: 950px) {
    .academy-box-descr {
        height: 160px;
    }
}

@media(max-width: 980px) {
    .academy-grid-contents .academy-box-content {
        width: 100%;
        margin: 0px 0px 10px;
        float: unset;
    }
    .academy-grid-contents {
        margin: 20px 0px 0px;
    }
    .academy-view-descr .academy-view-text,
    .academy-view-descr .academy-view-attachment {
        width: 100%;
        float: none;
    }
    .academy-view-attachment {
        margin-top: 50px;
    }
    .academy-view-box-video iframe {
        height: 230px !important;
    }
    .academy-view-box .academy-view-box-video {
        width: 100%;
    }
    .academy-view-box-video h3,
    .academy-view-box .academy-view-list-video h3 {
        text-align: center;
    }
    .academy-view-box {
        height: 520px;
        padding: 15px;
    }
    .academy-view-box .academy-view-list-video {
        width: 100%;
        padding-left: 0px;
    }
    .academy-boxthumb {
        padding: 10px;
    }
    .academy-view-container-list-video {
        margin-top: 25px;
    }
    .academy-view-container-list-video {
        height: 130px;
    }
}